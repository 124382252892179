div.VOCPieChart {
    margin-bottom: 50px;
    width: 100%;
}

div.VOCPieChartTitle {
    font-family: Arial;
    font-size: 20px;
    color: white;
    background-color: #652D8C;
    font-weight: bold;
    text-align: center;
    padding: 5px;
    border-radius: 20px;
    width: 90%;
    margin-left: 5%;
}

div.VOCPieChartCenter {
    display: inline-block;
    position: relative;
    font-size: 18px;
    top: 265px;
    color: navy;
    font-weight: bold;
}
