
div.bigIcon {
    display: inline-block;
    cursor: pointer;
}

div.outlined {
    border: 2px solid red;
}

img.showing {
    display: block;
}

img.hiding {
    display: block;
    opacity: 0.2;
    filter: grayscale(100%);
}

div.recordsShown {
    display: inline-block;
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: #652D8C;
    padding-bottom: 20px;
}


/*-----------------------*/
/* Career Report         */
/*-----------------------*/

.careerTableOddLine {
    background-color: #EEFFEE;
}

.careerTableEvenLine {
    background-color: #BBFFBB;
}

.carrerTableAscendingSort {
    background-color: #CCDDFF;
}

.carrerTableDescendingSort {
    background-color: #CCDDFF;
}

img.tinyHeader {
    width: 20px;
}

img.tiny {
    width: 25px;
    cursor: pointer;
    margin-top: -4px;
}

div.careerCell {
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: black;
    margin: -2px;
    vertical-align: middle;
    height: 22px;
    padding-top: 5px;
}

div.carrerCellNA {
    text-align: center;
    font-weight: bold;
    color: #652D8C;
    font-style: italic;
    font-size: 13px
}

div.careerCellButton {
    border: 2px orange solid;
    border-radius: 8px;
    background: white;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}

div.careerCellSoc {
    text-align: center;
    width: 80px;
}

div.careerCellTier {
    text-align: center;
    width: 40px;
    font-weight: bold;
}

div.careerCellOcc {
    text-align: left;
    width: 385px;
    padding-left: 5px;
}

div.careerCellFlags {
    text-align: left;
    width: 24px;
}

div.careerCellRegional {
    text-align: right;
    width: 60px;
    padding-right: 15px;
}

div.careerCellRJRatio {
    font-size: 14px;
    text-align: right;
    width: 70px;
    padding-right: 5px;
}

div.careerCellAnnual {
    text-align: right;
    width: 65px;
    padding-right: 25px;
}

div.careerCellRJRatioRed {
    color: blue;
    font-weight: bold;
}

div.careerCellRJRatioGreen {
    color: red;
    font-weight: bold;
}

div.careerCellHourly {
    text-align: right;
    width: 40px;
    padding-right: 25px;
}

div.careerCellJobs {
    text-align: right;
    width: 50px;
    padding-right: 15px;
}

div.careerCellChange {
    text-align: right;
    width: 60px;
    padding-right: 15px;
}

div.careerCellDefined {
    text-align: right;
    width: 40px;
    padding-right: 25px;
}

div.careerCellAvailable {
    text-align: right;
    width: 60px;
    padding-right: 10px;
    padding-left: 10px;
}

div.careerCellOnline {
    text-align: right;
    width: 40px;
    padding-right: 25px;
}

div.pinkLine {
    text-align: right;
    background-color: pink;
    width: 5px;
}

div.careerCellPain {
    text-align: right;
    width: 50px;
    padding-right: 40px;
}

div.careerCellWorkFromHome {
    text-align: right;
    width: 30px;
    padding-right: 25px;
}

div.careerCellStress {
    text-align: right;
    width: 35px;
    padding-right: 18px;
}

div.careerCellPay {
    text-align: right;
    width: 25px;
    padding-right: 15px;
}

div.careerCellEducation {
    text-align: right;
    width: 30px;
    padding-right: 10px;
}

div.careerCellHoursWorked {
    text-align: right;
    width: 35px;
    padding-right: 15px;
}

div.careerCellSafety {
    text-align: right;
    width: 40px;
    padding-right: 25px;
}

/*----------------------------------------*/

div.classesCell {
    background-color: #DDEEFF;
    font-family: Arial, sans-serif;
    font-size: 13px;
    color: black;
    border-top: 1px lightgray solid;
    margin: -2px;
    padding: 0 5px
}

div.classesCellProvider {
    width: 340px;
    text-align: left;
}

div.classesCellProgramName {
    width: 380px;
    text-align: left;
}

div.classesCellDuration {
    width: 100px;
    text-align: right;
}

div.classesCellTotalCost {
    width: 80px;
    text-align: right;
}

div.classesCellLearningPace {
    width: 200px;
    text-align: center;
}

div.classesCellAttendance {
    width: 200px;
    text-align: center;
}

/*----------------------------------------*/

div.classesTableContainer {
    width: calc(100% - 90px);
    overflow: auto;
    display: inline-block;
    border: 2px #CCCCCC solid;
}

@media screen and (max-width: 1024px) {
    div.careerTable {
        width: 1680px;
        zoom: 155%;
        max-height: 450px;
    }
}

div.careerTable {
    width: 1680px;
    max-height: 450px;
}

thead {
    position: sticky;
    top: 0;
}

div.classesTable {
    width: 1360px;
}

div.classesTableTitle {
    font-family: Arial, sans-serif;
    font-size: 20px;
    color: navy;
    font-weight: bold;
}
