div.top50Title {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 10px;
}
div.top50SectionTitle {
    font-family: Arial, sans-serif;
    font-size: 21px;
    font-weight: bold;
    padding: 5px;
}
div.top50SubSectionTitle {
    font-family: Arial, sans-serif;
    font-size: 20px;
    font-style: italic;
}

div.top50FootNote {
    padding: 10px;
    font-style: italic;
    text-align: left;
}

div.top50cell {
    font-family: Arial, sans-serif;
    font-size: 14px;
    text-align: right;
    color: black;
    background-color: #EEEEFF;
    margin: -2px;
}
div.top50cellRownum {
    color: green;
    width: 20px;
    padding: 2px 4px;
    text-align: right;
}
div.top50cellCommonSkill {
    width: 350px;
    padding: 2px 15px;
    text-align: left;
}
div.top50cellCommonPostings {
    width: 50px;
    padding: 2px 30px;
}
div.top50cellCommonProfiles {
    width: 50px;
    padding: 2px 30px;
}
div.top50cellCompany {
    width: 330px;
    padding: 2px 15px;
    text-align: left;
}
div.top50cellCompanyTotalPostings {
    width: 60px;
    padding: 2px 30px;
}
div.top50cellCompanyUniquePostings {
    width: 60px;
    padding: 2px 30px;
}
div.top50cellOccupation {
    width: 330px;
    padding: 2px 15px;
    text-align: left;
}
div.top50cellLabel {
    background-color: white;
    font-size: 16px;
}
div.top50cellOccupationTotalPostings {
    width: 60px;
    padding: 2px 30px;
}
div.top50cellOccupationUniquePostings {
    width: 60px;
    padding: 2px 30px;
}
div.top50cellQualifications {
    width: 450px;
    padding: 2px 15px;
    text-align: left;
}
div.top50cellQualificationsUniquePostings {
    width: 50px;
    padding: 2px 30px;
}
div.top50cellSoftware {
    width: 350px;
    padding: 2px 15px;
    text-align: left;
}
div.top50cellSoftwareTotalPostings {
    width: 50px;
    padding: 2px 30px;
}
div.top50cellSoftwareTotalProfiles {
    width: 50px;
    padding: 2px 30px;
}
div.top50cellSpecialized {
    width: 350px;
    padding: 2px 15px;
    text-align: left;
}
div.top50cellSpecializedTotalPostings {
    width: 50px;
    padding: 2px 30px;
}
div.top50cellSpecializedTotalProfiles {
    width: 50px;
    padding: 2px 30px;
}

