div.page {
    max-width: 1900px;
    min-width: 1024px;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #ECECEC;
    width: 100%;
}

div.title {
    font-family: Arial, sans-serif;
    color: white;
    background-color: #652D8C;
    text-align: center;
    font-weight: bold;
}

div.pageTitle {
    font-size: 26px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 20px;
}

div.sectionTitle {
    font-size: 21px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 20px;
}

div.width100 {
    width: 100%;
}

div.navigation {
    font-family: Arial, sans-serif;
    text-align: right;
    padding-top: 20px;
    margin-bottom: 10px;
    background-color: white;
}
button {
    font-family: Arial, sans-serif;
    cursor: pointer;
}

.linkDecoration {
    color: black;
    text-decoration: none;
}

button.nav {
    color: black;
    font-size: 20px;
    background-color: inherit;
    font-weight: bold;
    width: 250px;
    border: 0;
    padding-bottom: 15px;
}

button.navSelected {
    border-bottom: 12px solid #87C13F;
    cursor: initial;
}

button.navOpen {
    border-bottom: 12px solid #FFED00;
}

div.instructions {
    text-align: left;
    width: 660px;
    font-family: Arial, sans-serif;
    font-size: 16px;
    background-color: #FFED00;
    color: black;
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 25px;
}

div.instructionWide {

}

div.instructionNarrow {
    width: 330px;
    padding: 20px;
}

button.big {
    font-size: 15px;
    border: 0;
    color: #652D8C;
    background-color: #CCCCCC;
    padding: 10px;
    border-radius: 15px;
    font-weight: bold;
}
button.bigSelected {
    color: white;
    background-color: #652D8C;
}

button.buttonTop50 {
    border: 0;
    border-bottom: 5px solid inherit;
    padding-bottom: 10px;
    color: black;
}

button.buttonTop50Selected {
    border-bottom: 5px solid #87C13F;
    cursor: initial;
}

div.component {
    background-color: white;
    border-radius: 30px;
    padding-bottom: 5px;
}

img.title {
    width: 100%;
    margin: 0;
    padding: 0;
}

div.noResults {
    display: inline-block;
    font-family: Arial, sans-serif;
    font-size: 34px;
    font-weight: bold;
    font-style: italic;
    color: lightgray;
    padding: 50px 0;
    text-align: center;
}

div.selectForDetails {
    display: inline-block;
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: italic;
    color: #555555;
    padding: 10px 0;
    text-align: center;
}

select.large {
    border: 0;
    margin: 10px;
    padding: 5px;
    height: 40px;
    background-color: #C1ABD1;
    color: #652D8C;
    font-family: Arial, sans-serif;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.industries {
    width: 60%;
}

.industries80 {
    width: 80%;
}

.counties {
    width: 22%;
}


option {
    border: 0;
    background-color: #C1ABD1;
    margin: 10px;
    padding: 5px;
    color: #652D8C;
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: normal;
    height: 30px;
    width: 100%;
}

select.small {
    border: 0;
    background-color: #C1ABD1;
    margin-left: 10px;
    color: #652D8C;
    font-family: Arial, sans-serif;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.clearFloat {
    clear: both;
}

.attainment_logo {
    width: 300px;
    margin: 0;
    padding: 0;
}

.attainment_logo:hover {
    cursor: pointer;
}

.alignLeft {
    text-align: left;
}

.invisible {
    visibility: hidden;
}

.alignCenter {
    text-align: center;
}

.inlineBlock {
    display: inline-block;
}

div.fullWidth {
    witdh: 100%;
}

.markRed {
    border: 2px solid red;
}

div.largeGap {
    margin: 40px;
}
div.gap {
    margin: 20px;
}
div.smallGap {
    margin: 8px;
}

.floatLeft {
    float: left;
}

table {
    text-align: left;
    position: relative;
    border-collapse: collapse;
}

thead {
    position: sticky;
    top: 0;
}

thead > tr {
    background-color: white;
}

.demoVideoDesktop {
    display: inline-block;
}

.demoVideoMobile {
    display: none;
}

@media screen and (max-width: 1280px) {
    .demoVideoDesktop {
        display: none;
    }

    .demoVideoMobile {
        display: block;
    }
}
