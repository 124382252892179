div.pie {
    display: inline-block;
    width: 24%;
}

div.pie4 {
    display: inline-block;
    width: 24%;
}

div.pie35 {
    display: inline-block;
    width: 28%;
}

div.pie2 {
    display: inline-block;
    width: 48%;
}

div.pie3 {
    display: inline-block;
    width: 32%;
}

div.rowOfPieCharts {

}

div.tableauTemplate {
    display: inline-block;
}

div.pieChartBody {
    margin-top: 20px;
    text-align: center;
}
