div.footNotesShape {
    width: 950px;
}

div.footNotesBody {
    padding-left: 20px;
    text-align: left;
}

p.footNotes {
    font-family: Arial, sans-serif;
    font-size: 16px;
}
p.footNotesSubTitle {
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
}
ul.footNotesItems {
    font-size: 16px;
}

div.tableFootNotes {
    text-align: left;
    padding-left: 20px;
}

p.footNoteTitle {
    font-family: Arial, sans-serif;
    font-size: 20px;
}

span.footNoteBody {
    font-size: 18px;
    padding-left: 12px;
}

div.columns2 {
    display: inline-block;
    width: 48%;
    vertical-align: text-top;
}

div.columns-55 {
    display: inline-block;
    width: 55%;
    vertical-align: text-top;
}

div.columns-40 {
    display: inline-block;
    width: 40%;
    vertical-align: text-top;
}

div.columns3 {
    display: inline-block;
    width: 32%;
    vertical-align: text-top;
}

div.columns5 {
    display: inline-block;
    width: 20%;
    vertical-align: text-top;
}

p.indexKeyTitle {
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: bold;
    margin-right: 30px;
}
ol.footNotesList {
    font-size: 14px;
    margin-right: 15px;
}
ul.footNotesList {
    font-size: 14px;
    list-style-type: square;
    margin-right: 15px;
}

ul.footNotesListWithBullets {
    font-size: 14px;
    list-style-type: disc;
}

p.indexKeyBody {
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: normal;
    margin-right: 15px;
}

p.sourceTitle {
    vertical-align: top;
    font-family: Arial, sans-serif;
    font-size: 20px;
    color: #652D8C;
    font-weight: bold;
    margin-right: 35px;
    background-color: #82D0ED;
    padding: 10px;
}
ul.sourceList {
    color: #652D8C;
    font-size: 16px;
    list-style-type: square;
    margin-right: 15px;
}

