div.orderByBody {
    padding: 2px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
}
div.orderByCell {
    display: inline-block;
}
div.orderByTitle {
    width: 120px;
    font-size:20px;
}
div.orderByRadio {
    /*width: 150px;*/
    padding: 0 8px;
}
div.orderByRadioWide {
    width: 170px;
    padding: 0 8px;
}
input[type=radio] {
    transform: scale(1.5);
    margin-right: 7px;
    margin-top: 25px;
}

div.graphCenter {
    position: absolute;
    left: 520px;
    margin-top: 215px;
}

div.graphCenterRelative {
    position: absolute;
    left: 1330px;
    top: 3295px;
}
