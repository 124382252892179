div.leftSide {
    display: inline-block;
    width: calc(100% - 650px);
    min-width: 850px;
    margin-left: 8px;
}

div.rightSide {
    display: inline-block;
    width: 630px;
    vertical-align: top;
    margin-left: 10px;
}

div.leftSideHalf {
    display: inline-block;
    width: 49%;
    min-width: 900px;
    margin-left: 8px;
}

div.rightSideHalf {
    display: inline-block;
    width: 49%;
    vertical-align: top;
    min-width: 900px;
    margin-left: 10px;
}

div.top50 {
    width: 620px;
    text-align: center;
}
